import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Fab, makeStyles, Modal, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Add as AddIcon } from '@material-ui/icons';
import PageHeader from '../../components/PageHeader';
import List from './List';
// import Filter from './Filter';
import Form from './Form';
import * as api from '../../api/Campaign/api';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function Mestre() {
  const classes = useStyles();
  const [form, setForm] = useState({});
  const [formOpen, setFormOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});

  const fetch = useCallback(async (pageNumber = 0, size = 10) => {
    const result = await api.fetchCampaign(pageNumber, size);
    setData(result.data);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const toggleDrawer = (value) => {
    setForm({});
    setFormOpen(value);
  };

  const edit = (payload) => {
    setForm(payload);
    setFormOpen(true);
  };

  const callbackForm = (value) => {
    if (value) {
      setFormOpen(false);
      setForm({});
      setSuccess(true);
      fetch();
    }
  };

  return (
    <div>
      <PageHeader title='Listagem de Campanhas' subheading='Consulta' />
      <Grid container justify='center' alignItems='center'>
        <Grid item sm={12} md={12} lg={8} style={{ maxWidth: '100%' }}>
          <List data={data} fetch={fetch} edit={edit} />
        </Grid>
        <Fab
          className={classes.fab}
          color='primary'
          onClick={() => toggleDrawer(true)}
        >
          <AddIcon />
        </Fab>
        <Modal open={formOpen} onClose={() => toggleDrawer(false)}>
          <Form callbackForm={callbackForm} form={form} />
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={success}
          autoHideDuration={5000}
          onClose={() => setSuccess(false)}
        >
          <Alert variant='outlined' severity='success'>
            <AlertTitle>Sucesso</AlertTitle>
            Campanha criada com sucesso.
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}

export default Mestre;
