import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import PersistentDrawer from './PersistentDrawer';
import { drawerWidth } from '../../../theme';
import TemporaryDrawer from './TemporaryDrawer';

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
}));

export default function SideDrawer({ children, open, onClose }) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const Drawer = !isMobile ? TemporaryDrawer : PersistentDrawer;

  return (
    <nav className={classes.drawer}>
      <Drawer open={open} onClose={onClose}>
        {children}
      </Drawer>
    </nav>
  );
}

SideDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
