const TOKEN_KEY = 'authToken';

export function getTokenFromLocalStorage() {
  return localStorage.getItem(TOKEN_KEY);
}

export function saveTokenLocalStorage(token) {
  return localStorage.setItem(TOKEN_KEY, token);
}

export function removeTokenFromLocalStorage() {
  localStorage.setItem(TOKEN_KEY, null);
}
