const invalidCPF = [
  '00000000000',
  '11111111111',
  '22222222222',
  '33333333333',
  '44444444444',
  '55555555555',
  '66666666666',
  '77777777777',
  '88888888888',
  '99999999999',
];

export function cpfValidation(cpf) {
  let result = true;
  let soma = 0;

  const validator = (index) => {
    for (let i = 0; i < index; i += 1) {
      soma += cpf[i] * (index + 1 - i);
    }

    soma = (soma * 10) % 11;

    if (soma === 10 || soma === 11) {
      soma = 0;
    }

    if (soma.toString() !== cpf[index]) {
      result = false;
    }
    return result;
  };

  cpf = cpf.replace(/\.|-/g, '');

  if (cpf.length !== 11) {
    result = false;
  }

  invalidCPF.forEach((element) => {
    if (cpf === element) {
      result = false;
    }
    return result;
  });

  validator(9);

  if (soma.toString() === cpf[9]) {
    soma = 0;
    validator(10);
  }

  return result;
}
