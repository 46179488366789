import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Snackbar } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { useForm, Controller } from 'react-hook-form';
import {
  Alert,
  AlertTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';
import UButton from '../../../components/UButton';
import UTextField from '../../../components/UTextField';
import schema from './schema';
import * as campaignApi from '../../../api/Campaign/api';
import * as bonusApi from '../../../api/Bonus/api';
import moment from 'moment';
import { useFormStyles } from './styles';

let msgError = '';

function Form({ callbackForm, form, msgErro }) {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(
    form.type ? form.type : 'Value'
  );
  const [campaigns, setCampaigns] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState('');
  const campaign = campaigns?.find((c) => c.id === selectedCampaign);

  const fetchCampaign = useCallback(async (pageNumber = 0, size = 10) => {
    const result = await campaignApi.fetchCampaign(pageNumber, size);
    setCampaigns(result.data.content);
  }, []);

  useEffect(() => {
    fetchCampaign();
  }, [fetchCampaign]);

  useEffect(() => {
    setSelectedCampaign(form.campaignId);
  }, [form.campaignId]);

  const { handleSubmit, register, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: form.name,
      initDate: moment(form.initDate).format('yyyy-MM-DD'),
      endDate: moment(form.endDate).format('yyyy-MM-DD'),
      campaignId: form.campaignId,
      value: form.value,
      nrEnrollments: form.nrEnrollments,
      active: form.active === undefined ? true : form.active,
    },
  });

  const mountForm = (payload) => {
    const data = {
      id: form.id,
      name: payload.name,
      initDate: moment(payload.initDate).format('yyyy-MM-DD HH:mm:ss'),
      endDate: moment(payload.endDate).endOf('day').format('yyyy-MM-DD HH:mm:ss'),
      campaignId: selectedCampaign,
      value: payload.value,
      nrEnrollments: Number(payload.nrEnrollments),
      type: paymentMethod,
      active: payload.active,
      keycloakUserId: form.keycloakUserId,
    };
    return data;
  };

  const validator = (payload) => {
    if (selectedCampaign === '') {
      setError(true);
      msgError = 'Selecione uma campanha';
      return false;
    }

    const campaignInitDate = moment(campaign.initDate).format('yyyy-MM-DD');
    const campaignEndDate = moment(campaign.endDate).format('yyyy-MM-DD');
    const initDate = payload.initDate;
    const endDate = payload.endDate;

    if (moment(campaignInitDate).isAfter(initDate)) {
      setError(true);
      msgError =
        'Datas inicial e final devem estar dentro do período da campanha';
      return false;
    }

    if (moment(endDate).isAfter(campaignEndDate)) {
      setError(true);
      msgError =
        'Datas inicial e final devem estar dentro do período da campanha';
      return false;
    }

    if (moment(initDate).isAfter(endDate)) {
      setError(true);
      msgError = 'Data inicial posterior data final';
      return false;
    }
    return true;
  };

  const save = async (payload) => {
    if (validator(payload)) {
      if (form.id) {
        setLoading(true);
        await bonusApi.updateBonus(form.id, mountForm(payload));
        callbackForm(true);
      } else {
        try {
          setLoading(true);
          await bonusApi.saveBonus(mountForm(payload));
          callbackForm(true);
          msgErro('Bonificação cadastrado');
        } catch (err) {
          setError(true);
          msgError = 'Erro ao salvar bonificação';
        } finally {
          setLoading(false);
        }
      }
    }
  };

  const handleChangePayment = (event, value) => {
    if (value !== null) {
      setPaymentMethod(value);
    }
  };

  return (
    <div className={classes.modal}>
      <form className={classes.form} onSubmit={handleSubmit(save)} noValidate>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} sm={12}>
            <UTextField
              margin='normal'
              required
              fullWidth
              label='Nome da Bonificação'
              name='name'
              autoComplete='name'
              autoFocus
              inputRef={register}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.bonunamesName.message}
              disabled={loading}
            />
          </Grid>
          <Grid className={classes.selectInput} item xs={12} sm={6}>
            <UTextField
              select
              name='campaignId'
              label='Campanha'
              defaultValue='Selecione'
              onChange={(e) => setSelectedCampaign(e.target.value)}
              inputRef={register}
              error={Boolean(errors.campaignId)}
              helperText={errors.campaignId && errors.campaignId.message}
              disabled={loading}
              control={control}
              value={selectedCampaign}
            >
              {campaigns?.map((campaign) => (
                <MenuItem key={campaign?.id} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </UTextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12} sm={12} className={classes.titleLabels}>
              Datas início e fim da campanha
            </Grid>
            <Grid item xs={12} sm={12}>
              {campaign ? moment(campaign?.initDate).format('DD/MM/yyyy') : '-'}
            </Grid>
            <Grid item xs={12} sm={12}>
              {campaign ? moment(campaign?.endDate).format('DD/MM/yyyy') : '-'}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.paymentButtonContainer}>
            <Grid item className={classes.paymentButtonTitle}>
              Tipo de bonificação
            </Grid>
            <ToggleButtonGroup
              className={classes.paymentButtonGroup}
              color='primary'
              value={paymentMethod}
              exclusive
              onChange={handleChangePayment}
              aria-label='Tipo da bonificação'
            >
              <ToggleButton
                classes={{ selected: classes.selected }}
                value='Value'
              >
                {' '}
                ${' '}
              </ToggleButton>
              {/* <ToggleButton
                classes={{ selected: classes.selected }}
                value="Percentage"
              >
                {" "}
                %{" "}
              </ToggleButton> */}
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              className={
                paymentMethod === 'Value'
                  ? classes.amountInputValue
                  : classes.percentageInputValue
              }
              type='number'
              margin='normal'
              required
              fullWidth
              label='Valor do Bônus'
              name='value'
              autoComplete='value'
              inputRef={register}
              error={Boolean(errors.value)}
              helperText={errors.value && errors.value.message}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              type='date'
              margin='normal'
              required
              fullWidth
              label='Data do início'
              name='initDate'
              autoComplete='initDate'
              inputRef={register}
              error={Boolean(errors.initDate)}
              helperText={errors.initDate && errors.initDate.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              type='date'
              margin='normal'
              required
              fullWidth
              label='Data do fim'
              name='endDate'
              autoComplete='endDate'
              inputRef={register}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate && errors.endDate.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              margin='normal'
              required
              fullWidth
              label='Quantidade de matrículas'
              name='nrEnrollments'
              autoComplete='nrEnrollments'
              inputRef={register}
              error={Boolean(errors.nrEnrollments)}
              helperText={errors.nrEnrollments && errors.nrEnrollments.message}
              disabled={loading}
            />
          </Grid>
          <Grid container item xs={12} sm={3} className={classes.check}>
            <Grid item>Ativo</Grid>
            <Grid item>
              <Controller
                as={
                  <Switch
                    color='primary'
                    label='Top'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                name='active'
                control={control}
                defaultValue
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <UButton
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              size='large'
              disabled={loading}
            >
              Enviar
            </UButton>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        className={classes.snack}
        open={error}
        autoHideDuration={5000}
        onClose={() => setError(false)}
      >
        <Alert variant='filled' severity='error'>
          <AlertTitle>{msgError}</AlertTitle>
          Tente novamente.
        </Alert>
      </Snackbar>
    </div>
  );
}

Form.propTypes = {
  callbackForm: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
  msgErro: PropTypes.func.isRequired,
};

export default Form;
