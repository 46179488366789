import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, makeStyles, Snackbar } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import { useForm, Controller } from "react-hook-form";
import { Alert, AlertTitle } from "@material-ui/lab";
import { cpfValidation } from "../../../utils/format";
import UButton from "../../../components/UButton";
import UTextField from "../../../components/UTextField";
import schema from "./schema";
import * as api from "../../../api/Mestre/api";

let msgError = "";

const useFormStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& > div > div": {
      margin: "4px 0 2px 0px",
    },
  },
  form: {
    position: "relative",
    padding: "0px 30px 10px 30px",
    marginTop: theme.spacing(1),
  },
  button: {},
  check: {
    marginTop: "30px",
    marginLeft: "60px",
    alignItems: "center",
  },
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
    background: "#fff",
    borderRadius: "1%",
  },
  snack: {
    position: "absolute",
    top: "40%",
    height: "5px",
    paddingTop: "3%",
  },
  item: {
    padding: "0px !important",
  },
}));

function Form({ callbackForm, form, msgErro }) {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { handleSubmit, register, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: form.name,
      cpf: form.cpf,
      phone: form.phone,
      email: form.email,
      unity: form.unity,
      occupation: form.occupation,
      active: form.active === undefined ? true : form.active,
    },
  });

  const formatCPF = (cpf) => cpf.replace(/\.|-/g, "");

  const mountForm = (payload) => {
    const data = {
      id: form.id,
      name: payload.name.toUpperCase(),
      cpf: formatCPF(payload.cpf),
      phone: payload.phone,
      email: payload.email,
      unity: payload.unity.toUpperCase(),
      occupation: payload.occupation.toUpperCase(),
      active: payload.active,
      keycloakUserId: form.keycloakUserId,
    };
    return data;
  };

  const validator = async (payload) => {
    const resultCpf = await api.findByCpf(formatCPF(payload.cpf));
    const resultEmail = await api.findByEmail(payload.email);
    if (!cpfValidation(payload.cpf)) {
      setError(true);
      msgError = "CPF inválido!";
      return false;
    }
    if (form.id) {
      setLoading(true);
      await api.updateMestre(form.keycloakUserId, mountForm(payload));
      callbackForm(true);
      msgErro("Alteração concluída");
      return false;
    }
    if (resultCpf.status === 200) {
      setError(true);
      msgError = "Esse CPF já existe na base de dados";
      return false;
    }
    if (resultEmail.status === 200) {
      setError(true);
      msgError = "Esse E-mail já existe na base de dados";
      return false;
    }
    return true;
  };

  const save = async (payload) => {
    if (await validator(payload)) {
      try {
        setLoading(true);
        await api.saveMestre(mountForm(payload));
        callbackForm(true);
        msgErro("Participante cadastrado");
      } catch (err) {
        setError(true);
        msgError = "Erro ao salvar participante";
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.modal}>
      <form className={classes.form} onSubmit={handleSubmit(save)} noValidate>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} sm={12}>
            <UTextField
              margin="normal"
              required
              fullWidth
              label="Nome"
              name="name"
              autoComplete="name"
              autoFocus
              inputRef={register}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              margin="normal"
              required
              fullWidth
              label="CPF"
              name="cpf"
              autoComplete="cpf"
              inputRef={register}
              error={Boolean(errors.cpf)}
              helperText={errors.cpf && errors.cpf.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              margin="normal"
              fullWidth
              label="Telefone"
              name="phone"
              autoComplete="phone"
              inputRef={register}
              error={Boolean(errors.phone)}
              helperText={errors.phone && errors.phone.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              margin="normal"
              required
              fullWidth
              label="Unidade"
              name="unity"
              autoComplete="unity"
              inputRef={register}
              error={Boolean(errors.unity)}
              helperText={errors.unity && errors.unity.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              margin="normal"
              required
              fullWidth
              label="Cargo"
              name="occupation"
              autoComplete="occupation"
              inputRef={register}
              error={Boolean(errors.occupation)}
              helperText={errors.occupation && errors.occupation.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <UTextField
              margin="normal"
              required
              fullWidth
              label="E-mail"
              name="email"
              autoComplete="email"
              inputRef={register}
              error={Boolean(errors.email)}
              helperText={errors.email && errors.email.message}
              disabled={loading}
            />
          </Grid>
          <Grid container item xs={12} sm={3} className={classes.check}>
            <Grid item>Ativo</Grid>
            <Grid item>
              <Controller
                as={
                  <Switch
                    color="primary"
                    label="Top"
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                name="active"
                control={control}
                defaultValue
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <UButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
            >
              Enviar
            </UButton>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        className={classes.snack}
        open={error}
        autoHideDuration={5000}
        onClose={() => setError(false)}
      >
        <Alert variant="filled" severity="error">
          <AlertTitle>{msgError}</AlertTitle>
          Tente novamente.
        </Alert>
      </Snackbar>
    </div>
  );
}

Form.propTypes = {
  callbackForm: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
  msgErro: PropTypes.func.isRequired,
};

export default Form;
