import React
// , { useEffect }
  from 'react';
// import { useRequest } from '../../hooks/request';

function Dashboard() {
  // const { isLoading, fetch, data } = useRequest({ url: '/indication/status', method: 'GET' });

  // useEffect(() => {
  //   fetch();
  // }, [fetch]);

  return <div>Dashboard</div>;
}

export default Dashboard;
