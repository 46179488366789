/* eslint-disable no-template-curly-in-string */
import { setLocale } from 'yup';

setLocale({
  string: {
    length: 'O campo deve possuir exatamente ${length} caracteres',
    min: 'O campo deve possuir pelo menos ${min} caracteres',
    max: 'O campo deve possuir no máximo ${max} caracteres',
    matches: 'O campo valor informado não corresponde com o valor esperado',
    email: 'O campo deve ser um e-mail válido',
    url: 'O campo deve ser uma URL válida',
    lowercase: 'O campo deve ser um texto somente em letras minúsculas',
    uppercase: 'O campo deve ser um texto somente em letras maíusculas',
  },
  number: {
    min: 'O valor deve ser maior ou igual à ${min}',
    max: 'O valor deve ser menor ou igual à ${max}',
    lessThan: 'O valor deve ser menor que ${less}',
    moreThan: 'O valor deve ser maior que ${more}',
    notEqual: 'O valor não deve ser igual à ${notEqual}',
    positive: 'O valor deve ser um número positivo',
    negative: 'O valor deve ser um número negativo',
    integer: 'O valor deve ser um número inteiro',
  },
  date: {
    min: 'O campo deve ser mais recente que ${min}',
    max: 'O campo deve ser mais antigo que ${max}',
  },
  array: {
    min: 'O campo deve possuir no mínimo ${min} itens',
    max: 'O campo deve possuir ${max} itens ou menos',
  },
  mixed: {
    default: 'O campo é inválido',
    required: 'O campo é obrigatório',
    oneOf: 'O campo deve ser um dos seguintes valores: ${values}',
    notOneOf: 'O campo não deve ser um dos seguintes valores: ${values}',
    defined: 'O campo deve ser definido',
  },
});
