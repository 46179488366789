import { makeStyles } from '@material-ui/core';

export const useFormStyles = makeStyles((theme) => ({
  '&.MuiGrid-item': {
    padding: '0',
  },
  root: {
    flexGrow: 1,
  },
  form: {
    position: 'relative',
    left: '13%',
    width: '450px',
    padding: '20px 0',

    '@media (max-width: 425px)': {
      padding: '30px',
    },

    '@media (max-width: 375px)': {
      padding: '30px 50px',
    },
  },
  button: {},
  check: {
    marginLeft: '10px',
    alignItems: 'center',
  },
  paymentButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  paymentButtonTitle: {
    padding: '8px',
    fontSize: '0.875rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.00938em',
  },
  paymentButtonGroup: {
    paddingLeft: '8px',
    marginBottom: '10px',
    height: '40px',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    background: '#fff',
    borderRadius: '1%',
    overflowY: 'scroll',
    maxHeight: '580px',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: 'none',
      borderRadius: '0 1px 0 0',
    },
  },
  snack: {
    position: 'absolute',
    top: '40%',
    height: '5px',
    paddingTop: '3%',
  },
  selectInput: {
    marginTop: '16px',
    marginBottom: '8px',
    '& label': {
      fontSize: '0.875rem',
    },
    '& > div': {
      fontSize: '0.875rem',
      width: '100%',
      '& > div': {
        '& > svg': {
          right: '8px',
        },
      },
    },
  },
  amountInputValue: {
    '& div': {
      position: 'relative',
      '&::before': {
        content: '"R$"',
        position: 'absolute',
        top: '13px',
        left: '13px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      '& input': {
        paddingLeft: '30px',
      },
    },
  },
  percentageInputValue: {
    '& div': {
      position: 'relative',
      '&::after': {
        content: '"%"',
        position: 'absolute',
        right: '44px',
        top: '17px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      '& input': {
        // paddingRight: "30px",
      },
    },
  },
  titleLabels: {
    fontSize: '0.875rem',
    transform: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.00938em',
    marginBottom: '6px',
    marginTop: '26px',
  },
  selected: {
    '&&': {
      backgroundColor: '#00AFBF',
      color: '#fff',
    },
  },
}));
