import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().min(4).max(120).required('O campo é obrigatório!'),
  initDate: yup.string().required('Data de início é obrigatória'),
  endDate: yup.string().required('Data de fim é obrigatória'),
  value: yup.string().required('O campo é obrigatório!'),
  nrEnrollments: yup.string().required('O campo é obrigatório!'),
});

export default schema;
