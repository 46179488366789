import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  makeStyles,
  Typography,
  Snackbar,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Alert, AlertTitle } from '@material-ui/lab';
import UButton from '../../../components/UButton';
import * as api from '../../../api/Mestre/api';

const useFormStyles = makeStyles((theme) => ({
  form: {
    width: '300px',
    padding: '20px',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function FormUP({ callbackForm }) {
  const classes = useFormStyles();

  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onChangeFile = (e) => {
    setFile(e.target.files[0]);
  };

  const onSend = async () => {
    try {
      setLoading(true);
      const { data } = await api.upload(file);
      callbackForm(data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  }, [file]);

  return (
    <form className={classes.form}>
      <Grid style={{
        alignItems: 'center',
        textAlign: 'center',
      }}
      >
        <label htmlFor="raised-button-file">
          <input
            accept=".csv"
            className={classes.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple={false}
            type="file"
            onChange={onChangeFile}
          />
          <UButton
            type="button"
            variant="outlined"
            color="default"
            component="span"
            fullWidth
            className={classes.button}
            startIcon={<CloudUploadIcon />}
            disabled={loading}
          >
            Upload CSV
          </UButton>
        </label>
        {file === undefined
          ? (
            <Typography variant="subtitle2">Nenhum arquivo selecionado</Typography>
          ) : (
            <>
              <Typography variant="subtitle2">{file.name}</Typography>
              <UButton
                type="button"
                variant="contained"
                color="primary"
                component="span"
                fullWidth
                className={classes.button}
                onClick={onSend}
                disabled={loading}
              >
                Enviar
              </UButton>
            </>
          )}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={error}
          autoHideDuration={8000}
          onClose={() => setError(false)}
        >
          <Alert variant="filled" severity="error">
            <AlertTitle>Erro ao Importar</AlertTitle>
            Revise o CSV.
            <br />
            Utilize o template padrão (Com cabeçalho) disponível para download.
          </Alert>
        </Snackbar>
      </Grid>
    </form>
  );
}

FormUP.propTypes = {
  callbackForm: PropTypes.func.isRequired,
};

export default FormUP;
