import { makeStyles } from '@material-ui/core';

export const useFormStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  form: {
    position: 'relative',
    left: '13%',
    width: '450px',
    padding: '30px 0',
    marginTop: theme.spacing(1),

    '@media (max-width: 425px)': {
      padding: '30px',
    },

    '@media (max-width: 375px)': {
      padding: '30px 50px',
    },
  },
  button: {},
  check: {
    marginTop: '27px',
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '600px',
    background: '#fff',
    borderRadius: '1%',
    overflowY: 'scroll',
    maxHeight: '580px',
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.3)',
      outline: 'none',
      borderRadius: '0 1px 0 0',
    },
  },
  snack: {
    position: 'absolute',
    top: '40%',
    height: '5px',
    paddingTop: '3%',
  },
}));

export const useFormStylesPayment = makeStyles((theme) => ({
  root: {},
  containerTypes: {
    display: 'flex',

    '@media (max-width: 425px)': {
      flexDirection: 'column',
    },
  },
  containerTypesFirstChild: {
    display: 'flex',
    marginBottom: '12px',

    '@media (max-width: 425px)': {
      flexDirection: 'column',
    },
  },
  titleType: {
    fontSize: '15px',
  },
  titleLabels: {
    fontSize: '0.875rem',
    transform: 'none',
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.00938em',
    marginBottom: '4px',
    marginTop: '8px',
  },
  titlePayment: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  containerButton: {
    height: '40px',
    marginRight: '12px',
  },
  inputValue: {
    marginTop: '0',
    marginBottom: '0',
  },
  percentageInputValue: {
    marginTop: '0',
    marginBottom: '0',

    '& div': {
      position: 'relative',
      '&::after': {
        content: '"%"',
        position: 'absolute',
        right: '12px',
        top: '14px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      '& input': {
        paddingRight: '30px',
      },
    },
  },
  amountInputValue: {
    marginTop: '0',
    marginBottom: '0',

    '& div': {
      position: 'relative',
      '&::before': {
        content: '"R$"',
        position: 'absolute',
        left: '12px',
        top: '13px',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      '& input': {
        paddingLeft: '30px',
      },
    },
  },
  selected: {
    '&&': {
      backgroundColor: '#00AFBF',
      color: '#fff',
    },
  },
}));
