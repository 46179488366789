import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
  primary: { main: '#00AFBF', contrastText: '#ffffff' },
  secondary: { main: '#0f4dbf' },
};

export const drawerWidth = 240;

export default createMuiTheme({ palette });
