import React from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  makeStyles,
} from '@material-ui/core';
import UPaper from '../../../components/UPaper';
import Row from './Row';
import UTableHeading from '../../../components/UTableHead';
import MESSAGES from '../../../constants/messages';

const useListStyles = makeStyles({
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
});

function List({ data, fetch, edit }) {
  const classes = useListStyles();
  const { content, totalSize, size, pageNumber } = data;

  const handleChangePage = (event, newPage) => {
    fetch(newPage, size);
  };

  const handleChangeRowsPerPage = (event) => {
    fetch(0, parseInt(event.target.value, 10));
  };

  return (
    <TableContainer component={UPaper} gutterTop>
      <Grid container direction='column'>
        <Grid item lg={12} className={classes.tableWrapper}>
          <Table aria-label='tabela de resultados'>
            <TableHead>
              <TableRow>
                <UTableHeading>Nome</UTableHeading>
                <UTableHeading>Data de Início</UTableHeading>
                <UTableHeading>Data de Fim</UTableHeading>
                <UTableHeading>Ativa</UTableHeading>
                <UTableHeading>Opções</UTableHeading>
              </TableRow>
            </TableHead>
            <TableBody>
              {content &&
                content.map((row) => (
                  <Row key={row.id} row={row} edit={edit} />
                ))}
            </TableBody>
            <TableBody>
              <TableRow>
                <TablePagination
                  colSpan={5}
                  count={totalSize}
                  rowsPerPage={size || 10}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  labelRowsPerPage={MESSAGES.mostrarLinhas}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from} a ${to} de ${Number(count)}`
                  }
                  page={pageNumber}
                  onChangePage={handleChangePage}
                  backIconButtonProps={{
                    'aria-label': 'Pagina Anterior',
                  }}
                  nextIconButtonProps={{
                    'aria-label': 'Próxima Página',
                  }}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </TableContainer>
  );
}

List.propTypes = {
  fetch: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  data: PropTypes.any.isRequired,
};

export default List;
