import { privateApi } from '../config';

const URL = '/bonus';

export const fetchBonus = async (page = 0, size) =>
  privateApi.get(`${URL}?page=${page}&size=${size}`);

export const fetchBonusFilters = async (params) =>
  privateApi.get(`${URL}/find`, { params });

export const templateCsv = async () => privateApi.get(`${URL}/template-csv`);

export const reportBonus = async () =>
  privateApi.get(`${URL}/report`, { responseType: 'blob' });

export const upload = async (file) => {
  const formData = new FormData();
  formData.append('fileUpload', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return privateApi.post(`${URL}/upload`, formData, config);
};

export const saveBonus = async (bonus) => privateApi.post(`${URL}`, bonus);

export const findByCpf = async (cpf) => privateApi.get(`${URL}/cpf/${cpf}`);

export const findByEmail = async (email) =>
  privateApi.get(`${URL}/email/${email}`);

export const updateBonus = async (keycloakUserId, body) =>
  privateApi.put(`${URL}/${keycloakUserId}`, body);
