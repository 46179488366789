import React, { useCallback, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import LoadingSplash from '../../components/LoadingSplash';

function Login() {
  const { keycloak } = useKeycloak();

  const login = useCallback(() => {
    keycloak.login();
  }, [keycloak]);

  useEffect(() => {
    login();
  }, [login, keycloak]);

  return (
    <LoadingSplash />
  );
}

export default Login;
