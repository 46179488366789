import React from 'react';
import { TextField, makeStyles, fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useTextFieldStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  root: {
    width: '100%',
    padding: theme.spacing(0.5),
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& > div': {
      height: 'auto !important',
    },
    '& input + div': {
      alignSelf: 'center',
      marginLeft: 0,
      position: 'absolute',
      right: 1,
    },
    '& input + div > button': {
      '&:hover': {
        background: 'transparent',
      },
    },
  },
  input: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 2,
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    minHeight: 40,
    padding: '6px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 0.2rem ${fade(theme.palette.primary.main, 0.25)}`,
    },
    '&:disabled': {
      background: theme.palette.grey[50],
      color: theme.palette.text.disabled,
    },
  },
  inputError: {
    '& > input': {
      borderColor: theme.palette.error.main,
      '&:focus': {
        borderColor: theme.palette.error.main,
        boxShadow: `0 0 0 0.2rem ${fade(theme.palette.error.main, 0.25)}`,
      },
    },
    '& > textarea': {
      borderColor: theme.palette.error.main,
      '&:focus': {
        borderColor: theme.palette.error.main,
        boxShadow: `0 0 0 0.2rem ${fade(theme.palette.error.main, 0.25)}`,
      },
    },
  },
  label: {
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    transform: 'none',
  },
  helperText: {
    marginLeft: theme.spacing(1),
    fontSize: theme.typography.pxToRem(12),
  },
}));

function UTextField({
  name,
  error,
  type,
  multiline,
  InputProps,
  InputLabelProps,
  FormHelperTextProps,
  ...rest
}) {
  const classes = useTextFieldStyles();
  const rows = multiline ? { rows: 2 } : null;

  return (
    <TextField
      error={error}
      id={name}
      name={name}
      type={type}
      {...rows}
      multiline={multiline}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          input: clsx(
            classes.input,
            InputProps && InputProps.endAdornment ? classes.endAdornment : ''
          ),
          error: classes.inputError,
        },
        error,
        ...InputProps,
      }}
      InputLabelProps={{
        shrink: true,
        className: classes.label,
        error,
        ...InputLabelProps,
      }}
      FormHelperTextProps={{
        error,
        className: classes.helperText,
        ...FormHelperTextProps,
      }}
      {...rest}
    />
  );
}

UTextField.defaultProps = {
  error: false,
  InputLabelProps: null,
  InputProps: null,
  FormHelperTextProps: null,
  multiline: false,
  name: null,
  type: 'text',
};

UTextField.propTypes = {
  error: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  FormHelperTextProps: PropTypes.object,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
};

export default UTextField;
