import React from 'react';
import PropTypes from 'prop-types';
import { Paper, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  inset1x: {
    padding: theme.spacing(1),
  },
  inset2x: {
    padding: theme.spacing(2),
  },
  inset3x: {
    padding: theme.spacing(3),
  },
  gutterTop1x: {
    marginTop: theme.spacing(1),
  },
  gutterTop2x: {
    marginTop: theme.spacing(2),
  },
}));

function UPaper({ children, gutterTop, insetSize }) {
  const classes = useStyles();
  const gutterTopSize = typeof gutterTop === 'boolean' ? '1x' : gutterTop;
  const gutterTopNumberSize = ['1x', '2x'].includes(gutterTopSize) ? gutterTopSize : '1x';
  const gutterTopClass = `gutterTop${gutterTopNumberSize}`;

  const insetSizeNumber = ['1x', '2x', '3x'].includes(insetSize) ? insetSize : '2x';
  const insetSizeClass = `inset${insetSizeNumber}`;

  return (
    <Paper className={clsx(classes[insetSizeClass], classes[gutterTopClass])}>
      {children}
    </Paper>
  );
}

UPaper.defaultProps = {
  gutterTop: false,
  insetSize: '2x',
};

UPaper.propTypes = {
  children: PropTypes.node.isRequired,
  gutterTop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(['1x', '2x'])]),
  insetSize: PropTypes.oneOf(['1x', '2x', '3x']),
};

export default UPaper;
