import Keycloak from 'keycloak-js';

function getUrlService() {
  if (process.env.REACT_APP_ENV === 'production') {
    return 'https://sso.platosedu.io/auth/';
  }
  if (process.env.REACT_APP_ENV === 'staging') {
    return 'https://sso.stage.platosedu.io/auth/';
  }
  return 'https://sso.dev.platosedu.io/auth/';
}

const keycloak = new Keycloak({
  url: getUrlService(),
  realm: 'kroton',
  clientId: 'front-indica-admin',
});

export default keycloak;
