import React, { useState, useEffect } from "react";
import {
  makeStyles,
  CssBaseline,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  useMediaQuery,
} from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  SchoolSharp as MestreIcon,
  LocalActivity as LocalActivityIcon,
  RecentActors as IndicationIcon,
  AttachMoneyTwoTone as BonusIcon,
} from "@material-ui/icons";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import SideDrawer from "./SideDrawer";
import AppToolbar from "./AppToolbar";
import { Paths } from "../../router/routes";
import { drawerWidth } from "../../theme";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    overflow: "auto",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
  },
  contentShift: {
    [theme.breakpoints.up("md")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  },
}));

const InternalTemplate = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const isMobileBreakpoint = useMediaQuery((theme) =>
    theme.breakpoints.down("sm")
  );
  const [drawerOpen, setDrawerOpen] = useState(!isMobileBreakpoint);

  const toggleDrawerOpen = () => setDrawerOpen(!drawerOpen);

  useEffect(() => {
    if (setDrawerOpen && isMobileBreakpoint) {
      setDrawerOpen(false);
    }
  }, [setDrawerOpen, isMobileBreakpoint]);

  const handleMestre = () => history.push(Paths.MESTRE);
  const handleCampaign = () => history.push(Paths.CAMPAIGN);
  const handleIndication = () => history.push(Paths.INDICATION);
  const handleBonus = () => history.push(Paths.BONUS);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppToolbar onMenuClick={toggleDrawerOpen} />
      <SideDrawer open={drawerOpen} onClose={toggleDrawerOpen}>
        <Hidden smDown implementation="css">
          <div className={classes.appBarSpacer} />
        </Hidden>
        <List>
          <ListItem button>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <Divider />
          <ListItem button onClick={handleMestre}>
            <ListItemIcon>
              <MestreIcon />
            </ListItemIcon>
            <ListItemText primary="Participantes" />
          </ListItem>
          <ListItem button onClick={handleCampaign}>
            <ListItemIcon>
              <LocalActivityIcon />
            </ListItemIcon>
            <ListItemText primary="Campanhas" />
          </ListItem>
          <ListItem button onClick={handleIndication}>
            <ListItemIcon>
              <IndicationIcon />
            </ListItemIcon>
            <ListItemText primary="Indicações" />
          </ListItem>
          <ListItem button onClick={handleBonus}>
            <ListItemIcon>
              <BonusIcon />
            </ListItemIcon>
            <ListItemText primary="Bonificações" />
          </ListItem>
        </List>
      </SideDrawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: drawerOpen,
        })}
      >
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
};

InternalTemplate.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InternalTemplate;
