import React, {
  createContext, useContext, useState, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { initializeWebsocket } from './websocket';

const initialState = {
  ws: null,
  connected: false,
  lastResponse: null,
  initializeWebsocket: null,
};

const WebSocketContext = createContext(initialState);

export function WebSocketProvider({ children }) {
  const [state, setState] = useState(initialState);

  useEffect(() => setState((oldValue) => ({
    ...oldValue,
    initializeWebsocket: () => {
      setState((old) => ({ ...old, ws: initializeWebsocket({ setState }) }));
    },
  })),
  [setState]);

  return (
    <WebSocketContext.Provider value={state}>
      {children}
    </WebSocketContext.Provider>
  );
}

WebSocketProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useWebSocket = () => useContext(WebSocketContext);
