import { privateApi } from '../config';

const URL = '/campaign';
const URL_ANEXO = '/anexo';

export const fetchCampaign = async (page = 0, size) =>
  privateApi.get(`${URL}?page=${page}&size=${size}`);

export const saveCampaign = async (body) => privateApi.post(`${URL}`, body);

export const updateCampaign = async (id, body) =>
  privateApi.put(`${URL}/${id}`, body);

export const validateIfThereIsAlreadyCampaign = async (
  initDatePayload,
  endDatePayload
) =>
  privateApi.get(
    `${URL}/is-active-and-current?initDate=${initDatePayload}&endDate=${endDatePayload}`
  );

export const saveImage = async (body) => privateApi.post(`${URL_ANEXO}`, body);
