import React from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from './keycloak';
import { WebSocketProvider } from './WebSocketContext';
import './i18n/locale';
import App from './App';
import { saveTokenLocalStorage } from './api/token';

ReactDOM.render(
  <React.StrictMode>
    <ReactKeycloakProvider authClient={keycloak} onTokens={(tokens) => saveTokenLocalStorage(tokens.token)}>
      <WebSocketProvider>
        <App />
      </WebSocketProvider>
    </ReactKeycloakProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
