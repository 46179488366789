import { Grid } from '@material-ui/core';
import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import UTextField from '../../../components/UTextField';
import { useFormStylesPayment } from './styles';

function PaymentSectionForm(props) {
  const classes = useFormStylesPayment();
  const typeAmount = props.eadPaymentMethod === 'amount';
  const typePercentage = props.eadPaymentMethod === 'percentage';

  // EAD
  const choiceEadPaymentType = (event, value) => {
    if (value !== null) {
      props.setEaDPaymentMethod(value);
      if (value === 'percentage') {
        props.setEadPaymentValue('');
      } else {
        props.setEadPaymentValue(0);
      }
      props.setEadMaxValue(0);
    }
  };

  function choiceEadPaymentValue(value) {
    props.setEadPaymentValue(value);
  }

  function choiceEadMaxValue(value) {
    props.setEadMaxValue(value);
  }

  // Presencial
  const choicePresencialPaymentType = (event, value) => {
    if (value !== null) {
      props.setPresencialPaymentMethod(value);
      if (value === 'percentage') {
        props.setPresencialPaymentValue('');
      } else {
        props.setPresencialPaymentValue(0);
      }
      props.setPresencialMaxValue(0);
    }
  };

  function choicePresencialPaymentValue(value) {
    props.setPresencialPaymentValue(value);
  }

  function choicePresencialMaxValue(value) {
    props.setPresencialMaxValue(value);
  }

  const validateMaxPercentage = (value) => {
    if (value > 100) {
      return (value = 100);
    } else {
      return value;
    }
  };

  return (
    <Grid item xs={12} sm={12}>
      <Grid item xs={12} sm={6} className={classes.titlePayment}>
        Valores por indicação
      </Grid>
      <Grid className={classes.titleType} item xs={12} sm={6}>
        EAD
      </Grid>
      <Grid className={classes.containerTypesFirstChild}>
        <Grid item xs={12} sm={4}>
          <Grid className={classes.titleLabels} item xs={12} sm={12}>
            Tipo de pagamento
          </Grid>
          <ToggleButtonGroup
            color='primary'
            value={props.eadPaymentMethod}
            exclusive
            onChange={choiceEadPaymentType}
            aria-label='Platform'
            className={classes.containerButton}
          >
            <ToggleButton
              classes={{ selected: classes.selected }}
              value='amount'
            >
              {' '}
              ${' '}
            </ToggleButton>
            <ToggleButton
              classes={{ selected: classes.selected }}
              value='percentage'
            >
              {' '}
              %{' '}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid className={classes.titleLabels} item xs={12} sm={12}>
            Valor
          </Grid>
          <UTextField
            placeholder={typeAmount ? '' : '0'}
            className={
              typeAmount
                ? classes.amountInputValue
                : classes.percentageInputValue
            }
            type='number'
            margin='normal'
            required
            fullWidth
            value={
              typePercentage
                ? validateMaxPercentage(props.eadPaymentValue)
                : props.eadPaymentValue
            }
            onChange={(e) => choiceEadPaymentValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid className={classes.titleLabels} item xs={12} sm={12}>
            Valor Máximo
          </Grid>
          <UTextField
            disabled={typeAmount}
            placeholder={typeAmount ? '-' : '0'}
            type='number'
            margin='normal'
            required
            fullWidth
            className={classes.amountInputValue}
            value={props.eadMaxValue}
            onChange={(e) => choiceEadMaxValue(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid className={classes.titleType} item xs={12} sm={6}>
        Presencial
      </Grid>
      <Grid className={classes.containerTypes}>
        <Grid item xs={12} sm={4}>
          <Grid className={classes.titleLabels} item xs={12} sm={12}>
            Tipo de pagamento
          </Grid>
          <ToggleButtonGroup
            color='primary'
            value={props.presencialPaymentMethod}
            exclusive
            onChange={choicePresencialPaymentType}
            aria-label='Platform'
            className={classes.containerButton}
          >
            <ToggleButton
              classes={{ selected: classes.selected }}
              className='buttonPayment'
              value='amount'
            >
              {' '}
              ${' '}
            </ToggleButton>
            <ToggleButton
              classes={{ selected: classes.selected }}
              value='percentage'
            >
              {' '}
              %{' '}
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid className={classes.titleLabels} item xs={12} sm={12}>
            Valor
          </Grid>
          <UTextField
            placeholder={props.presencialPaymentMethod === 'amount' ? '' : '0'}
            type='number'
            margin='normal'
            required
            fullWidth
            className={
              props.presencialPaymentMethod === 'amount'
                ? classes.amountInputValue
                : classes.percentageInputValue
            }
            value={
              props.presencialPaymentMethod === 'percentage'
                ? validateMaxPercentage(props.presencialPaymentValue)
                : props.presencialPaymentValue
            }
            onChange={(e) => choicePresencialPaymentValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Grid className={classes.titleLabels} item xs={12} sm={12}>
            Valor Máximo
          </Grid>
          <UTextField
            disabled={props.presencialPaymentMethod === 'amount'}
            placeholder={props.presencialPaymentMethod === 'amount' ? '-' : '0'}
            type='number'
            margin='normal'
            required
            fullWidth
            className={classes.amountInputValue}
            value={props.presencialMaxValue}
            onChange={(e) => choicePresencialMaxValue(e.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentSectionForm;
