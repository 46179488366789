import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Snackbar, Tooltip, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import { Alert, AlertTitle } from '@material-ui/lab';
import UButton from '../../../components/UButton';
import UTextField from '../../../components/UTextField';
import schema from './schema';
import * as api from '../../../api/Campaign/api';
import PaymentSectionForm from './PaymentSectionForm';
import { useFormStyles } from './styles';

const alunoPDFNameFile = 'aluno_ex_aluno.pdf';
const polosPDFNameFile = 'polos.pdf';
const campiPDFNameFile = 'campi.pdf';

let msgError = '';

const fileRegulation = {
  fileRegAluno: new File([''], 'filename'),
  fileRegPolos: new File([''], 'filename'),
  fileRegCampi: new File([''], 'filename'),
};

function Form({ callbackForm, form }) {
  let initialEadPaymentMethod = 'amount';
  let initialEadPaymentValue = 0;
  let initialEadMaxValue = 0;
  let initialPresencialPaymentMethod = 'amount';
  let initialPresencialPaymentValue = 0;
  let initialPresencialMaxValue = 0;

  if (form.payments) {
    initialEadPaymentMethod = form.payments[0].paymentType || 'amount';
    initialEadPaymentValue = form.payments[0].amount || 0;
    initialEadMaxValue = form.payments[0].maxAmount || 0;
    initialEadMaxValue = form.payments[0].maxAmount || 0;
    initialPresencialPaymentMethod = form.payments[1].paymentType || 'amount';
    initialPresencialPaymentValue = form.payments[1].amount || 0;
    initialPresencialMaxValue = form.payments[1].maxAmount || 0;
  }

  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const formCampaign = new FormData();

  const [eadPaymentMethod, setEaDPaymentMethod] = useState(
    initialEadPaymentMethod
  );
  const [eadPaymentValue, setEadPaymentValue] = useState(
    initialEadPaymentValue
  );
  const [eadMaxValue, setEadMaxValue] = useState(initialEadMaxValue);
  const [presencialPaymentMethod, setPresencialPaymentMethod] = useState(
    initialPresencialPaymentMethod
  );
  const [presencialPaymentValue, setPresencialPaymentValue] = useState(
    initialPresencialPaymentValue
  );
  const [presencialMaxValue, setPresencialMaxValue] = useState(
    initialPresencialMaxValue
  );

  function uploadFiles(e) {
    const file = e.target.files[0];
    const arq = e.target.id;
    if (file.size >= 5000000) {
      setError(true);
      msgError = 'Arquivo muito grande!';
    } else if (file.type !== 'application/pdf') {
      setError(true);
      msgError = 'Somente arquivo no formato PDF!';
    } else {
      fileRegulation[arq] = file;
      setError(false);
    }
  }

  const { handleSubmit, register, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: form.name,
      initDate: moment(form.initDate).format('yyyy-MM-DD'),
      endDate: moment(form.endDate).format('yyyy-MM-DD'),
      active: form.active === undefined ? true : form.active,
      paymentDay: form.paymentDay,
      messageText: form.messageText,
    },
  });

  async function validate(initDatePayload, endDatePayload) {
    try {
      const response = await api.validateIfThereIsAlreadyCampaign(
        initDatePayload,
        endDatePayload
      );
      if (JSON.stringify(response.data) === 'true') {
        return true;
      }
      if (JSON.stringify(response.data) === 'false') {
        return false;
      }
    } catch (error2) {
      msgError = error2;
    }
    return null;
  }

  const mountForm = (payload) => {
    formCampaign.append('name', payload.name);
    formCampaign.append('paymentDay', Number(payload.paymentDay));
    formCampaign.append('active', payload.active);
    formCampaign.append(
      'initDate',
      moment(payload.initDate, 'yyyy-MM-DD').format('yyyy-MM-DD HH:mm:ss')
    );
    formCampaign.append(
      'endDate',
      moment(payload.endDate, 'yyyy-MM-DD').format('yyyy-MM-DD HH:mm:ss')
    );

    formCampaign.append('payments[0].modalidade', 'EAD');
    formCampaign.append('payments[0].paymentType', eadPaymentMethod);
    formCampaign.append('payments[0].amount', eadPaymentValue);
    formCampaign.append('payments[0].maxAmount', eadMaxValue);
    formCampaign.append('payments[1].modalidade', 'PRESENCIAL');
    formCampaign.append('payments[1].paymentType', presencialPaymentMethod);
    formCampaign.append('payments[1].amount', presencialPaymentValue);
    formCampaign.append('payments[1].maxAmount', presencialMaxValue);

    formCampaign.append(
      'alunoPdf',
      fileRegulation.fileRegAluno,
      alunoPDFNameFile
    );
    formCampaign.append(
      'polosPdf',
      fileRegulation.fileRegPolos,
      polosPDFNameFile
    );
    formCampaign.append(
      'campiPdf',
      fileRegulation.fileRegCampi,
      campiPDFNameFile
    );
    formCampaign.append('messageText', payload.messageText);
  };

  const validateDate = (date) => {
    const dateCurrent = new Date();
    const dateParams = new Date(`${date} 00:00:00`);
    if (dateParams < dateCurrent) {
      return true;
    }
    return false;
  };

  const save = async (payload) => {
    if (form.id) {
      setLoading(true);
      mountForm(payload);
      await api.updateCampaign(form.id, formCampaign);
      callbackForm(true);
    } else if (validateDate(payload.endDate)) {
      setError(true);
      msgError = 'Escolha uma data final mais adiante.';
    } else if (payload.endDate < payload.initDate) {
      setError(true);
      msgError =
        'Data inicial maior que a final ou a data final menor que a inicial.';
    } else if (await validate(payload.initDate, payload.endDate)) {
      setError(true);
      msgError = 'Já existe uma campanha ativa nesse alcance de data.';
    } else if (document.getElementById('fileRegAluno').files.length === 0) {
      setError(true);
      msgError = 'Regulamento Aluno e Ex-aluno é obrigatório.';
    } else if (document.getElementById('fileRegPolos').files.length === 0) {
      setError(true);
      msgError = 'Regulamento Polos é obrigatório.';
    } else if (document.getElementById('fileRegCampi').files.length === 0) {
      setError(true);
      msgError = 'Regulamento Campi é obrigatório.';
    } else {
      try {
        setLoading(true);
        mountForm(payload);
        await api.saveCampaign(formCampaign);
        callbackForm(true);
      } catch (err) {
        setError(true);
        msgError = 'Erro ao salvar campanha';
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className={classes.modal}>
      <form className={classes.form} onSubmit={handleSubmit(save)} noValidate>
        <Grid container className={classes.root} spacing={1}>
          <Grid item xs={12} sm={12}>
            <UTextField
              margin='normal'
              required
              fullWidth
              label='Nome da campanha'
              name='name'
              autoComplete='name'
              autoFocus
              inputRef={register}
              error={Boolean(errors.name)}
              helperText={errors.name && errors.name.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              type='date'
              margin='normal'
              required
              fullWidth
              label='Data de início'
              name='initDate'
              autoComplete='initDate'
              inputRef={register}
              error={Boolean(errors.initDate)}
              helperText={errors.initDate && errors.initDate.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              type='date'
              margin='normal'
              required
              fullWidth
              label='Data de fim'
              name='endDate'
              autoComplete='endDate'
              inputRef={register}
              error={Boolean(errors.endDate)}
              helperText={errors.endDate && errors.endDate.message}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <PaymentSectionForm
              eadPaymentMethod={eadPaymentMethod}
              setEaDPaymentMethod={setEaDPaymentMethod}
              eadPaymentValue={eadPaymentValue}
              setEadPaymentValue={setEadPaymentValue}
              eadMaxValue={eadMaxValue}
              setEadMaxValue={setEadMaxValue}
              presencialPaymentMethod={presencialPaymentMethod}
              setPresencialPaymentMethod={setPresencialPaymentMethod}
              presencialPaymentValue={presencialPaymentValue}
              setPresencialPaymentValue={setPresencialPaymentValue}
              presencialMaxValue={presencialMaxValue}
              setPresencialMaxValue={setPresencialMaxValue}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UTextField
              InputProps={{
                inputProps: { min: 0, max: 31, 'data-action': 'Hello' },
              }}
              type='number'
              margin='normal'
              required
              fullWidth
              label='Dia de recebimento'
              name='paymentDay'
              autoComplete='paymentDay'
              inputRef={register}
              error={Boolean(errors.paymentDay)}
              helperText={errors.paymentDay && errors.paymentDay.message}
              disabled={loading}
            />
          </Grid>
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            item
            xs={12}
            sm={6}
            className={classes.check}
          >
            <Grid item>Ativo</Grid>
            <Grid item>
              <Controller
                as={
                  <Switch
                    color='primary'
                    label='Top'
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                name='active'
                control={control}
                defaultValue={false}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tooltip title='Tamanho do arquivo até 5MB!' placement='left'>
              <UTextField
                type='file'
                required
                label='Regulamento Aluno e Ex-aluno'
                name='fileRegAluno'
                id='fileRegAluno'
                fullWidth
                disabled={loading}
                onChange={uploadFiles}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tooltip title='Tamanho do arquivo até 5MB!' placement='left'>
              <UTextField
                type='file'
                required
                label='Regulamento Polos'
                name='fileRegPolos'
                id='fileRegPolos'
                fullWidth
                disabled={loading}
                onChange={uploadFiles}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Tooltip title='Tamanho do arquivo até 5MB!' placement='left'>
              <UTextField
                type='file'
                required
                label='Regulamento Campi'
                name='fileRegCampi'
                id='fileRegCampi'
                fullWidth
                disabled={loading}
                onChange={uploadFiles}
              />
            </Tooltip>
            <Typography
              variant='caption'
              display='block'
              gutterBottom
              align='right'
            >
              Tamanho do arquivo até 5MB!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <UTextField
              margin='normal'
              required
              fullWidth
              label='Texto para Redes Sociais'
              name='messageText'
              autoComplete='messageText'
              multiline
              maxRows={4}
              inputRef={register}
              error={Boolean(errors.messageText)}
              helperText={errors.messageText && errors.messageText.message}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <UButton
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              size='large'
              disabled={loading}
            >
              Salvar
            </UButton>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        className={classes.snack}
        open={error}
        autoHideDuration={5000}
        onClose={() => setError(false)}
      >
        <Alert variant='filled' severity='error'>
          <AlertTitle>{msgError}</AlertTitle>
          Tente novamente.
        </Alert>
      </Snackbar>
    </div>
  );
}

Form.propTypes = {
  callbackForm: PropTypes.func.isRequired,
  form: PropTypes.any.isRequired,
};

export default Form;
