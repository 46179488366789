import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import {
  routes,
  publicRoutes,
  PublicHomeRoute,
  PrivateHomeRoute,
} from './routes';
import PublicRoute from '../components/PublicRoute';
import InternalTemplate from '../components/InternalTemplate';
import PrivateRoute from '../components/PrivateRoute';
import LoadingSplash from '../components/LoadingSplash';

const renderWithTemplate = (component) => () => {
  const RouteComponent = component;
  return (
    <InternalTemplate>
      <RouteComponent />
    </InternalTemplate>
  );
};

const Router = () => {
  const { keycloak, initialized } = useKeycloak();
  if (!initialized) {
    return <LoadingSplash />;
  }

  return (
    <BrowserRouter basename='/indica'>
      <Switch>
        <Route path='/health'>
          <h3>Its healthy</h3>
        </Route>
        {publicRoutes.map((route) => (
          <PublicRoute
            isAuthenticated={keycloak.authenticated}
            key={route.path}
            exact={route.exact}
            path={route.path}
            component={route.component}
            redirectPath={PrivateHomeRoute}
          />
        ))}
        {routes.map((route) => (
          <PrivateRoute
            isAuthenticated={keycloak.authenticated}
            key={route.path}
            exact={route.exact}
            path={route.path}
            render={renderWithTemplate(route.component)}
            redirectPath={PublicHomeRoute}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
};
export default Router;
