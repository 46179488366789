import React, { useState } from 'react';
import {
  makeStyles,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  Table,
  TableHead,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import moment from 'moment';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

function Row({ row, edit }) {
  const [open, setOpen] = useState();
  const classes = useRowStyles();

  return (
    <>
      <TableRow key={row.name} className={classes.root}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.cpf}</TableCell>
        <TableCell>{row.code ? row.code : 'Sem código'}</TableCell>
        <TableCell>{row.valid ? 'Sim' : 'Não'}</TableCell>
        <TableCell>{row.active ? 'Sim' : 'Não'}</TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            onClick={() => edit(row)}
            startIcon={<EditIcon />}
          >
            Editar
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Card className={classes.card} variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom component="p">
                  Dados complementares
                </Typography>
                <Table aria-label="assinaturas">
                  <TableHead>
                    <TableRow>
                      <TableCell>Cadastro</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Telefone</TableCell>
                      <TableCell>Unidade</TableCell>
                      <TableCell>Cargo</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{moment(row.createdAt).format('DD/MM/yyyy')}</TableCell>
                      <TableCell>{row.email || '-'}</TableCell>
                      <TableCell>{row.phone || '-'}</TableCell>
                      <TableCell>{row.unity}</TableCell>
                      <TableCell>{row.occupation}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </CardContent>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    unity: PropTypes.string.isRequired,
    occupation: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    active: PropTypes.bool.isRequired,
    createdAt: PropTypes.any.isRequired,
  }).isRequired,
  edit: PropTypes.func.isRequired,
};

export default Row;
