import React, { useCallback, useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';
import PageHeader from '../../components/PageHeader';
import List from './List';
import * as api from '../../api/Indication/api';
import UFab from '../../components/UFab';

const useFormStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Indication() {
  const classes = useFormStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const fetch = useCallback(async (pageNumber = 0, size = 10) => {
    const result = await api.fetchIndication(pageNumber, size);
    setData(result.data);
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const onReport = async () => {
    try {
      setLoading(true);
      const result = await api.reportIndication();
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `indicacoes-${moment().format('HHmmss')}.csv`
      );
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const actions = [
    {
      icon: <PrintIcon />,
      name: 'Exportar relatório CSV',
      color: 'primary',
      onClick: onReport,
    },
  ];

  return (
    <div>
      <PageHeader title='Listagem de Indicações' subheading='Consulta' />
      <Grid container justify='center' alignItems='center'>
        <Grid item sm={12} md={12} lg={8} style={{ maxWidth: '100%' }}>
          <List data={data} fetch={fetch} />
        </Grid>
      </Grid>
      <UFab actions={actions} />
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}

export default Indication;
