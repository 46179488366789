import React, { useState, useEffect, useCallback } from 'react';
import {
  makeStyles, Grid, Drawer, Snackbar, Backdrop, CircularProgress, Modal,
} from '@material-ui/core';
import moment from 'moment';
import { Alert, AlertTitle } from '@material-ui/lab';
import PrintIcon from '@material-ui/icons/Print';
import CloudUpload from '@material-ui/icons/CloudUpload';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PageHeader from '../../components/PageHeader';
import List from './List';
import UFab from '../../components/UFab';
import Filter from './Filter';
import Form from './Form';
import FormUP from './Upload';
import * as api from '../../api/Mestre/api';

const useFormStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Mestre() {
  const classes = useFormStyles();
  const [form, setForm] = useState({});
  const [formOpen, setFormOpen] = useState(false);
  const [formOpenModal, setFormOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataFetch, setDataFetch] = useState({});
  const [msgError, setMsgError] = useState('');
  const [filteredList, setFilteredList] = useState([]);

  const sizeObj = (obj) => {
    let sizeField = 0;
    Object.values(obj).forEach((val) => {
      if (val !== '') {
        sizeField += 1;
      }
    });
    return sizeField;
  };

  const fetchMestre = useCallback(async (pageNumber = 0, size = 10) => {
    const {
      name,
      cpf,
      code,
      active,
    } = filteredList;

    const result = sizeObj(filteredList);
    if (result === 0) {
      const { data } = await api.fetchMestre(pageNumber, size);
      setDataFetch(data);
    } else if (result <= 1 && active === true) {
      const { data } = await api.fetchMestre(pageNumber, size);
      setDataFetch(data);
    } else {
      const { data } = await api.fetchMestreFilters({
        pageNumber,
        size,
        name,
        cpf,
        code,
        active,
      });
      setDataFetch(data);
    }
  }, [filteredList]);

  useEffect(() => {
    fetchMestre();
  }, [fetchMestre, msgError]);

  const toggleDrawer = (value) => {
    setFormOpen(value);
  };

  const toggleModal = (value) => {
    setForm({});
    setFormOpenModal(value);
  };

  const edit = (payload) => {
    setForm(payload);
    setFormOpenModal(true);
  };

  const onReport = async () => {
    try {
      setLoading(true);
      const { data } = await api.reportMestre();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `mestres-${moment().format('HHmmss')}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setError('Erro ao fazer download do relatório');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const downloadTemplateCsv = async () => {
    try {
      setLoading(true);
      const { data } = await api.templateCsv();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template-csv-participante.csv');
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setError('Erro ao fazer download do template');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const callbackForm = (value) => {
    if (value) {
      setFormOpenModal(false);
      setForm({});
      setSuccess(true);
      setFilteredList('');
    }
  };

  const msgErro = (msg) => {
    setMsgError(msg);
  };

  const handleFormFilters = (data) => {
    setFilteredList(data);
  };

  const actions = [
    {
      icon: <PrintIcon />,
      name: 'Exportar relatório CSV',
      color: 'primary',
      onClick: onReport,
    },
    {
      icon: <CloudUpload />,
      name: 'Upload CSV',
      color: 'primary',
      onClick: toggleDrawer,
    },
    {
      icon: <CloudDownloadIcon />,
      name: 'Download template CSV',
      color: 'primary',
      onClick: downloadTemplateCsv,
    },
    {
      icon: <AddCircleIcon />,
      name: 'Adicionar Participante',
      color: 'primary',
      onClick: toggleModal,
    },
  ];

  return (
    <div>
      <PageHeader title="Listagem de Participantes" subheading="Consulta" />
      <Grid container justify="center" alignItems="center">
        <Grid item sm={12} md={12} lg={8} style={{ maxWidth: '100%' }}>
          <Filter onSubmit={handleFormFilters} />
          <List dataFetch={dataFetch} fetchMestre={fetchMestre} edit={edit} />
        </Grid>
        <UFab actions={actions} />
        <Drawer anchor="right" open={formOpen} onClose={() => toggleDrawer(false)}>
          <FormUP callbackForm={callbackForm} />
        </Drawer>
        <Modal open={formOpenModal} onClose={() => toggleModal(false)}>
          <Form callbackForm={callbackForm} form={form} msgErro={msgErro} />
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={success}
          autoHideDuration={5000}
          onClose={() => setSuccess(false)}
        >
          <Alert variant="outlined" severity="success">
            <AlertTitle>Sucesso</AlertTitle>
            {msgError}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={error}
          autoHideDuration={5000}
          onClose={() => setError(null)}
        >
          <Alert variant="outlined" severity="error">
            <AlertTitle>Erro</AlertTitle>
            {msgError}
          </Alert>
        </Snackbar>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading} onClick={() => setLoading(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Mestre;
