function registerEvents(socket, { setState }) {
  if (socket) {
    socket.onopen = function open() {
      setState((oldState) => ({ ...oldState, socket, connected: true }));
    };
    socket.onmessage = function message(msg) {
      setState((oldState) => ({ ...oldState, lastResponse: msg.data }));
    };
    socket.onclose = function close() {
      setState((oldState) => ({ ...oldState, socket: null, connected: false }));
      socket = null;
    };
  }
}

export function initializeWebsocket({ setState }) {
  const username = 'lgdias';
  const ws = new WebSocket(`ws://localhost:8080/web/${username}`);
  registerEvents(ws, { setState });
  return ws;
}
