import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  userId: null,
  username: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticationSuccess(state, { payload }) {
      const { userId, username } = payload;
      state.userId = userId;
      state.username = username;
      state.isAuthenticated = true;
      return state;
    },
    authenticationFailed(state) {
      state.isAuthenticated = false;
      return state;
    },
    logout(state) {
      state = { ...initialState, isAuthenticated: false };
      return state;
    },
  },
});

export const {
  authenticationSuccess,
  authenticationFailed,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
