import React, { useState } from 'react';
import {
  makeStyles,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Typography,
  Table,
  TableHead,
  Card,
  CardContent,
} from '@material-ui/core';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import moment from 'moment';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

function Row({ row }) {
  const [open, setOpen] = useState();
  const classes = useRowStyles();

  return (
    <>
      <TableRow key={row.name} className={classes.root}>
        <TableCell>{moment(row.createdAt).format('DD/MM/yyyy')}</TableCell>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell>{row.cpf}</TableCell>
        <TableCell>{row.enrollment.unity}</TableCell>
        <TableCell>{row.campaign.name}</TableCell>
        <TableCell>{row.enrollment.course}</TableCell>
        <TableCell>{row.enrollment.origin}</TableCell>
        <TableCell>{row.enrollment.status}</TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Card className={classes.card} variant='outlined'>
              <CardContent>
                <Typography variant='h6' gutterBottom component='p'>
                  Dados complementares
                </Typography>
                <Table aria-label='assinaturas'>
                  <TableHead>
                    <TableRow>
                      <TableCell>Mestre</TableCell>
                      <TableCell>Metre CPF</TableCell>
                      <TableCell>Mestre Código</TableCell>
                      <TableCell>Matrícula</TableCell>
                      <TableCell>Número Pedido</TableCell>
                      <TableCell>Data Inscrição</TableCell>
                      <TableCell>Data Pagamento</TableCell>
                      <TableCell>Oferta Polo</TableCell>
                      <TableCell>Voucher</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{row.mestre.name}</TableCell>
                      <TableCell>{row.mestre.cpf}</TableCell>
                      <TableCell>{row.mestre.code}</TableCell>
                      <TableCell>{row.enrollment.enrollmentId}</TableCell>
                      <TableCell>{row.enrollment.orderNumber}</TableCell>
                      <TableCell>{row.enrollment.subscriptionDate}</TableCell>
                      <TableCell>{row.enrollment.paymentDate}</TableCell>
                      <TableCell>{row.enrollment.ofertaPolo}</TableCell>
                      <TableCell>{row.enrollment.voucher}</TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </CardContent>
            </Card>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    cpf: PropTypes.string.isRequired,
    campaign: {
      name: PropTypes.string.isRequired,
    },
    mestre: {
      name: PropTypes.string.isRequired,
      cpf: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    },
    enrollment: {
      unity: PropTypes.string.isRequired,
      course: PropTypes.string.isRequired,
      origin: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      matricula: PropTypes.string.isRequired,
      orderNumber: PropTypes.string.isRequired,
      subscriptionDate: PropTypes.string.isRequired,
      paymentDate: PropTypes.string.isRequired,
      ofertaPolo: PropTypes.string.isRequired,
      voucher: PropTypes.string.isRequired,
    },
  }).isRequired,
};

export default Row;
