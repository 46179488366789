import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Hidden,
  makeStyles,
  Select,
  InputLabel,
} from '@material-ui/core';
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import UPaper from '../../../components/UPaper';
import UTextField from '../../../components/UTextField';
import UButton from '../../../components/UButton';

const useFormStyles = makeStyles((theme) => ({
  panelSummary: {
    margin: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  panelDetails: {
    padding: theme.spacing(1),
  },
  panelHeading: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '32%',
    flexShrink: 0,
  },
  panelSubHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    alignSelf: 'center',
  },
  panelSearchIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  form: {
    width: '100%',
  },
  label: {
    padding: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    transform: 'none',
  },
  selectCombo: {
    height: '42px',
  },
  select: {
    marginTop: '42px',
    paddingLeft: '10px',
    fontSize: 15,
    cursor: 'pointer',
  },
}));

function Filter({ onSubmit }) {
  const classes = useFormStyles();
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [code, setCode] = useState('');
  const [active, setActive] = useState(true);

  const handlePanelExpand = () => setExpanded(!expanded);

  const handleSendFilters = () => {
    onSubmit({
      name,
      cpf,
      code,
      active,
    });
    setExpanded(false);
  };

  const handleClearFields = () => {
    setCpf('');
    setName('');
    setCode('');
    setActive(true);
    onSubmit({
      name: '',
      cpf: '',
      code: '',
      active,
    });
    setExpanded(false);
  };

  const handleChange = (event) => {
    setActive(event.target.value);
  };

  return (
    <ExpansionPanel
      component={UPaper}
      insetSize="1x"
      expanded={expanded}
      onChange={handlePanelExpand}
    >
      <ExpansionPanelSummary
        classes={{ content: classes.panelSummary }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography
          className={classes.panelHeading}
          variant="h6"
          component="h2"
        >
          <SearchIcon className={classes.panelSearchIcon} />
          Filtrar
        </Typography>
        {!expanded && (
          <Hidden
            className={classes.panelSubHeading}
            smDown
            implementation="css"
          >
            <Typography>Clique para filtrar os resultados</Typography>
          </Hidden>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        <form className={classes.form}>
          <Grid container direction="row">
            <Grid item md={4} xs={12}>
              <UTextField
                label="Nome"
                name="name"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <UTextField
                label="CPF"
                name="cpf"
                fullWidth
                onChange={(e) => setCpf(e.target.value)}
                value={cpf}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <UTextField
                label="Código"
                name="code"
                fullWidth
                onChange={(e) => setCode(e.target.value)}
                value={code}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <InputLabel htmlFor="active-label" className={classes.label}>Ativo</InputLabel>
              <Select
                className={classes.selectCombo}
                labelId="active-label"
                fullWidth
                value={active}
                onChange={handleChange}
                variant="outlined"
                MenuProps={{ classes: { paper: classes.select } }}
              >
                <option value="true" selected="selected">Sim</option>
                <option value="false">Não</option>
              </Select>
            </Grid>
          </Grid>
          <Grid container direction="row">
            <Grid item md={2}>
              <UButton
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSendFilters}
              >
                Buscar
              </UButton>
            </Grid>
            <Grid item md={2}>
              <UButton
                type="button"
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleClearFields}
              >
                Limpar
              </UButton>
            </Grid>
          </Grid>
        </form>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

Filter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Filter;
