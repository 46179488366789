import React, { useState, useEffect, useCallback } from 'react';
import {
  makeStyles,
  Grid,
  Snackbar,
  Backdrop,
  CircularProgress,
  Modal,
  Fab,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Add as AddIcon } from '@material-ui/icons';
import PageHeader from '../../components/PageHeader';
import List from './List';
import Form from './Form';
import * as api from '../../api/Bonus/api';

const useFormStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function Bonus() {
  const classes = useFormStyles();
  const [form, setForm] = useState({});
  const [formOpenModal, setFormOpenModal] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataFetch, setDataFetch] = useState({});
  const [msgError, setMsgError] = useState('');

  const fetchBonus = useCallback(async (pageNumber = 0, size = 10) => {
    const { data } = await api.fetchBonus(pageNumber, size);
    setDataFetch(data);
  }, []);

  useEffect(() => {
    fetchBonus();
  }, [fetchBonus, msgError]);

  const toggleModal = (value) => {
    setForm({});
    setFormOpenModal(value);
  };

  const edit = (payload) => {
    setForm(payload);
    setFormOpenModal(true);
  };

  const callbackForm = (value) => {
    if (value) {
      setFormOpenModal(false);
      setForm({});
      setSuccess(true);
      fetchBonus();
    }
  };

  const msgErro = (msg) => {
    setMsgError(msg);
  };

  return (
    <div>
      <PageHeader title='Listagem de Bonificações' subheading='Consulta' />
      <Grid container justify='center' alignItems='center'>
        <Grid item sm={12} md={12} lg={9} style={{ maxWidth: '100%' }}>
          <List dataFetch={dataFetch} fetchBonus={fetchBonus} edit={edit} />
        </Grid>
        <Fab
          className={classes.fab}
          color='primary'
          onClick={() => toggleModal(true)}
        >
          <AddIcon />
        </Fab>
        <Modal open={formOpenModal} onClose={() => toggleModal(false)}>
          <Form callbackForm={callbackForm} form={form} msgErro={msgErro} />
        </Modal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={success}
          autoHideDuration={5000}
          onClose={() => setSuccess(false)}
        >
          <Alert variant='outlined' severity='success'>
            <AlertTitle>Sucesso</AlertTitle>
            {msgError}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={error}
          autoHideDuration={5000}
          onClose={() => setError(null)}
        >
          <Alert variant='outlined' severity='error'>
            <AlertTitle>Erro</AlertTitle>
            {msgError}
          </Alert>
        </Snackbar>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={loading}
        onClick={() => setLoading(false)}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}

export default Bonus;
