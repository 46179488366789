import React from 'react';
import {
  makeStyles,
  Grid,
  CircularProgress,
} from '@material-ui/core';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 100px)',
  },
}));

function LoadingSplash() {
  const classes = useStyles();

  return (
    <Grid container justify="center" alignItems="center" component="main" className={classes.root}>
      <Grid item>
        <CircularProgress size={100} />
      </Grid>
    </Grid>
  );
}

export default LoadingSplash;
