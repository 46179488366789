import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = (
  { isAuthenticated, redirectPath, ...rest },
) => {
  if (isAuthenticated) {
    return <Route {...rest} />;
  }
  return <Redirect to={redirectPath} />;
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string.isRequired,
};

export default PrivateRoute;
