export const TENANT_ID = 'kroton';

export const APP_CONFIG_LOCAL_DEVELOPMENT = {
  login: 'https:localhost:8080/api/login',
  api: 'http://localhost:8080',
  ws: 'ws://localhost:8080',
};

export const APP_CONFIG_DEVELOPMENT = {
  login: `https://${TENANT_ID}.dev.platosedu.io/api/login`,
  api: `https://${TENANT_ID}.dev.platosedu.io/api/service-indica`,
  ws: `ws://${TENANT_ID}.dev.platosedu.io/api/service-indica`,
};

export const APP_CONFIG_STAGING = {
  login: `https://${TENANT_ID}.stage.platosedu.io/api/login`,
  api: `https://${TENANT_ID}.stage.platosedu.io/api/service-indica`,
  ws: `ws://${TENANT_ID}.stage.platosedu.io/api/service-indica`,
};

export const APP_CONFIG_PRODUCTION = {
  login: `https://${TENANT_ID}.platosedu.io/api/login`,
  api: `https://${TENANT_ID}.platosedu.io/api/service-indica`,
  ws: `ws://${TENANT_ID}.platosedu.io/api/service-indica`,
};

export const CONFIG = (() => {
  const env = process.env.REACT_APP_ENV;

  switch (env) {
    case 'production': {
      return APP_CONFIG_PRODUCTION;
    }

    case 'staging': {
      return APP_CONFIG_STAGING;
    }

    case 'development': {
      return APP_CONFIG_DEVELOPMENT;
    }

    default:
    case 'local': {
      return APP_CONFIG_LOCAL_DEVELOPMENT;
    }
  }
})();
