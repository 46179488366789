import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, makeStyles } from '@material-ui/core';
import { drawerWidth } from '../../../../theme';

const useStyles = makeStyles({
  drawerPaper: {
    width: drawerWidth,
    flexShrink: 1,
  },
});

export default function PersistentDrawer({ children, open, onClose }) {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      variant="persistent"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {children}
    </Drawer>
  );
}

PersistentDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
