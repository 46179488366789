import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().min(4).max(120).required('O campo é obrigatório!'),
  cpf: yup.string().min(11).max(14).required('O campo é obrigatório!'),
  phone: yup.string().required('O campo é obrigatório!'),
  email: yup.string().email('E-mail inválido').required('O campo é obrigatório!'),
  unity: yup.string().min(4).max(120).required('O campo é obrigatório!'),
  occupation: yup.string().min(4).max(120).required('O campo é obrigatório!'),
});

export default schema;
