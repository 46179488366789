import * as yup from 'yup';

const schema = yup.object().shape({
  name: yup.string().min(4).max(120).required('Nome da campanha é obrigatório'),
  paymentDay: yup.string().max(120).required('Dia de pagamento é obrigatório'),
  initDate: yup.string().required('Data de início é obrigatória'),
  endDate: yup.string().required('Data de fim é obrigatória'),
  messageText: yup
    .string()
    .min(4)
    .max(120)
    .required('Mensagem do whatsapp é obrigatório'),
});

export default schema;
