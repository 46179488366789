import { privateApi } from '../config';

const URL = '/mestre';
const URL_UPDATE = '/user/mestre';

export const fetchMestre = async (page = 0, size) => (
  privateApi.get(`${URL}?page=${page}&size=${size}`)
);

export const fetchMestreFilters = async (params) => (
  privateApi.get(`${URL}/find`, { params })
);

export const templateCsv = async () => (
  privateApi.get(`${URL}/template-csv`)
);

export const reportMestre = async () => (
  privateApi.get(`${URL}/report`, { responseType: 'blob' })
);

export const upload = async (file) => {
  const formData = new FormData();
  formData.append('fileUpload', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return privateApi.post(`${URL}/upload`, formData, config);
};

export const saveMestre = async (mestre) => privateApi.post(`${URL}`, mestre);

export const findByCpf = async (cpf) => privateApi.get(`${URL}/cpf/${cpf}`);

export const findByEmail = async (email) => privateApi.get(`${URL}/email/${email}`);

export const updateMestre = async (keycloakUserId, body) => (
  privateApi.put(`${URL_UPDATE}/${keycloakUserId}`, body)
);
