import LoginPage from '../pages/Login';
import DashboardPage from '../pages/Dashboard';
import MestrePage from '../pages/Mestre';
import CampaignPage from '../pages/Campaign';
import IndicationPage from '../pages/Indication';
import BonusPage from '../pages/Bonus';

export const Paths = {
  DASHBOARD: '/',
  LOGIN: '/login',
  MESTRE: '/mestre',
  CAMPAIGN: '/campaign',
  INDICATION: '/indication',
  BONUS: '/bonus',
};

export const routes = [
  {
    path: Paths.DASHBOARD,
    exact: true,
    component: DashboardPage,
  },
  {
    path: Paths.MESTRE,
    exact: true,
    component: MestrePage,
  },
  {
    path: Paths.CAMPAIGN,
    exact: true,
    component: CampaignPage,
  },
  {
    path: Paths.INDICATION,
    exact: true,
    component: IndicationPage,
  },
  {
    path: Paths.BONUS,
    exact: true,
    component: BonusPage,
  },
];

export const publicRoutes = [
  {
    path: Paths.LOGIN,
    exact: true,
    component: LoginPage,
  },
];

export const PublicHomeRoute = Paths.LOGIN;
export const PrivateHomeRoute = Paths.DASHBOARD;
export const PrivateMestreRoute = Paths.MESTRE;
export const PrivateCampaignRoute = Paths.CAMPAIGN;
export const PrivateIndicationRoute = Paths.INDICATION;
export const PrivateBonusRoute = Paths.BONUS;
