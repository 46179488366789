import React from 'react';
import { makeStyles, TableRow, TableCell, styled } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import moment from 'moment';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },

    '&.MuiButtonBase-root': {
      heigth: '200px',
    },
  },
}));

const EditButton = styled(Button)({
  padding: '6px 12px',
  backgroundColor: '##00AFBF',
  minWidth: '40px',
  '& span': {
    '& span': {
      marginRight: '0',
    },
  },
});

function Row({ row, edit }) {
  const classes = useRowStyles();
  const isCurrency = row.type === 'Value';

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return (
    <>
      <TableRow key={row.name} className={classes.root}>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell>{row.campaignName}</TableCell>
        <TableCell>{moment(row.initDate).format('DD/MM/yyyy')}</TableCell>
        <TableCell>{moment(row.endDate).format('DD/MM/yyyy')}</TableCell>
        <TableCell>
          {isCurrency ? formatter.format(row.value) : row.value + '%'}
        </TableCell>
        <TableCell>{row.active ? 'Ativo' : 'Inativo'}</TableCell>
        <TableCell>
          <EditButton
            variant='contained'
            color='primary'
            size='small'
            className={classes.editButton}
            onClick={() => edit(row)}
            startIcon={<EditIcon />}
          ></EditButton>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    campaignId: PropTypes.string.isRequired,
    nrEnrollments: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    initDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  edit: PropTypes.func.isRequired,
};

export default Row;
