import React from 'react';
import { makeStyles, TableRow, TableCell } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import moment from 'moment';

const useRowStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  card: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

function Row({ row, edit }) {
  const classes = useRowStyles();

  return (
    <>
      <TableRow key={row.name} className={classes.root}>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell>{moment(row.initDate).format('DD/MM/yyyy')}</TableCell>
        <TableCell>{moment(row.endDate).format('DD/MM/yyyy')}</TableCell>
        <TableCell>{row.active ? 'Sim' : 'Não'}</TableCell>
        <TableCell>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className={classes.button}
            onClick={() => edit(row)}
            startIcon={<EditIcon />}
          >
            Editar
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    initDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
  }).isRequired,
  edit: PropTypes.func.isRequired,
};

export default Row;
